import router from '@/router'
let token = localStorage.getItem('token')
let whiteList = ['/forget', '/register']
// 路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  let userAgent = navigator.userAgent;
  // 解决ios 第一次分享无效问题
  if ((userAgent.includes("iPhone") || userAgent.includes("iPad")) && !sessionStorage.getItem('originUrl')) {
    sessionStorage.setItem("originUrl", location.href); // 用于ios分享
  }
  if(token) {
    if(to.path === '/register') {
      next('/')
    }else {
      next()
    }
  }else {
    if(whiteList.includes(to.path)) {
      next()
    }else {
      next('/register')
    }
  }
})
